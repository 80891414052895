<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Item card</h3>

	<p>From the framework <span v-html="link('treeview', 'tree view')"></span>, click on the text or icon of any item to see its “card”*:</p>
	<p><img alt="Item card image" srcset="/docimages/item_card-1.png 2x" class="k-help-img"></p>
	<ul>
		<li>At the top of the card, you’ll see the item’s human-readable code (if the item <i>has</i> a code; it is <b>MA19.K.OA.A</b> in the screenshot above) and full statement. Click the <v-icon small>fas fa-copy</v-icon> button to copy the code and full statement to your clipboard.</li>
		<li>In the upper-right corner, you’ll see small icon buttons to <v-icon small color="green" style="transform:rotate(30deg)">fas fa-thumbtack</v-icon> <span v-html="link('pinning', '“pin”')"></span> the item or <v-icon small>fas fa-expand</v-icon> <span v-html="link('expanded_card', 'expand')"></span> the card.</li>
		<li>If the item includes notes and/or supplemental information, they will appear below the full statement. (The item pictured above include a note but does not include supplemental information.)</li>
		<li>Below the statement (and notes/supplemental information, if present), you will see the item’s Type (if a type is assigned), Education Level (if assigned), and the date the item was last changed.</li>
		<li>To the right of the last change date, you will see small icon buttons that allow you to:<ul>
			<li><v-icon small>fas fa-code</v-icon> <span v-html="link('more_info', 'view additional item metadata')"></span> and see a detailed history of archived changes to the item</li>
			<li><v-icon small>fas fa-print</v-icon> <span v-html="link('export_options', 'print')"></span> the item (as well as any children of the item in the framework hierarchy).</li>
		</ul></li>
		<li>If the item has child items, you will also see a button at the bottom of the card to <v-icon small>fas fa-search</v-icon> <span v-html="link('search', 'search')"></span> for search terms in children of the item.</li>
		<li><b>*Note:</b> on a small screen (e.g. a smartphone), clicking an item will initially show only the full statement of the item; click the <v-icon small>fas fa-expand</v-icon> icon to show the <span v-html="link('expanded_card', 'expanded card')"></span>, where the additional information noted above will be shown.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	